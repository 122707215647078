import { FC } from "react";
import { FlexElement } from "../elements/flex-element/flex-element";

type Props = {
  onClose: () => void;
};

const DeclineSuccessful: FC<Props> = (props) => (
  <FlexElement style={{ flexDirection: "column", gap: 30 }}>
    <h1>Bedankt voor het invullen!</h1>
    <p>Jammer dat je er niet bij kunt zijn. We drinken er een op jou!</p>
    <p>Groetjes, Henk & Antine</p>
    <FlexElement style={{ alignItems: "flex-end" }}>
      <button type="button" onClick={props.onClose}>
        Sluiten
      </button>
    </FlexElement>
  </FlexElement>
);

export { DeclineSuccessful };
