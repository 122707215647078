import { ReactNode, useCallback, useRef, useState } from "react";

export interface IModalHook {
  modal: ReactNode | null;
  openModal: <ResolveType = null>(
    content: (resolve: (response: ResolveType) => void) => ReactNode
  ) => Promise<ResolveType | null>;
  closeModal: () => void;
}

/**
 * A hook to manage the modal component
 * @returns
 */
export function useModal(): IModalHook {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Ref is used to store the modal which can return any type
  const resolveRef = useRef<((value: any | null) => void) | null>(null);
  const [modal, setModal] = useState<ReactNode | null>(null);

  const openModal = useCallback(
    async <ResolveType = null>(
      content: (resolve: (response: ResolveType | null) => void) => ReactNode,
      width?: number
    ) => {
      // resolve already opened modals
      resolveRef.current?.(null);

      // wait for the modal to be resolved
      return new Promise<ResolveType | null>((resolve) => {
        resolveRef.current = (response: ResolveType | null) => {
          // clean up the resolved modal
          resolveRef.current = null;
          setModal(null);
          document.body.style.overflow = "";

          // send the response
          resolve(response);
        };

        // set the new modal and move the resolve method to the state
        setModal(content(resolveRef.current));
        document.body.style.overflow = "hidden";
      });
    },
    []
  );

  /**
   * Closes any opened modals and resolves them with null
   */
  const closeModal = useCallback(() => {
    resolveRef.current?.(null);
  }, []);

  return {
    modal,
    openModal,
    closeModal,
  };
}
