import { FC, useCallback } from "react";
import { IInvitationResponse } from "../../domain/invitation-responses/invitation-response.interface";
import { FlexElement } from "../elements/flex-element/flex-element";
import rsvpHeaderSvg from "../../assets/rsvp-header.svg";

type Props = {
  model?: IInvitationResponse;
  onSubmit: (model: FormData) => void | Promise<void>;
  onCancel: () => void;
};

const RsvpForm: FC<Props> = (props) => {
  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const formdata = new FormData(event.currentTarget);
      // disable all inputs and buttons during submit
      const inputs = Array.from<
        HTMLInputElement | HTMLButtonElement | HTMLTextAreaElement
      >(event.currentTarget.querySelectorAll("input"))
        .concat(Array.from(event.currentTarget.querySelectorAll("button")))
        .concat(Array.from(event.currentTarget.querySelectorAll("textarea")));
      inputs.forEach((input) => (input.disabled = true));

      try {
        await props.onSubmit(formdata);
      } catch (error) {
        throw error;
      } finally {
        inputs.forEach((input) => (input.disabled = false));
      }
    },
    [props]
  );

  return (
    <form onSubmit={handleSubmit}>
      <FlexElement style={{ flexDirection: "column", gap: 50 }}>
        <FlexElement style={{ gap: 30 }}>
          <FlexElement style={{ alignItems: "center", gap: 30 }}>
            <img height={30} src={rsvpHeaderSvg} alt="Details" />
            <p>Hier kun je je aanwezigheid doorgeven.</p>
          </FlexElement>
          <FlexElement
            style={{ flexDirection: "row", flexWrap: "wrap", gap: 30 }}
          >
            <FlexElement>
              <h2>
                <label htmlFor="firstName">Voornaam *</label>
              </h2>
              <input type="text" id="firstName" name="firstName" required />
            </FlexElement>
            <FlexElement>
              <h2>
                <label htmlFor="lastName">Achternaam *</label>
              </h2>
              <input type="text" id="lastName" name="lastName" required />
            </FlexElement>
          </FlexElement>
          <FlexElement
            style={{ flexDirection: "row", flexWrap: "wrap", gap: 30 }}
          >
            <FlexElement>
              <h2>
                <label htmlFor="email">E-mail *</label>
              </h2>
              <input type="email" id="email" name="email" required />
            </FlexElement>
          </FlexElement>
          <FlexElement>
            <h2>
              <label htmlFor="remarks">Opmerkingen</label>
            </h2>
            <textarea id="remarks" name="remarks" />
          </FlexElement>
          <FlexElement style={{ gap: 20 }}>
            <h2>Aanwezigheid *</h2>
            <FlexElement style={{ flexDirection: "row", alignItems: "center" }}>
              <input
                required
                value="true"
                type="radio"
                name="willBeAttending"
                id="willBeAttending"
              />
              <label htmlFor="willBeAttending">
                Ik zal aanwezig zijn op het feest
              </label>
            </FlexElement>
            <FlexElement style={{ flexDirection: "row", alignItems: "center" }}>
              <input
                value="false"
                type="radio"
                name="willBeAttending"
                id="willNotBeAttending"
              />
              <label htmlFor="willNotBeAttending">
                Ik zal <strong>niet</strong> aanwezig zijn op het feest
              </label>
            </FlexElement>
          </FlexElement>
        </FlexElement>

        <FlexElement
          style={{ flexDirection: "row", justifyContent: "space-between" }}
        >
          <button type="button" onClick={props.onCancel}>
            Sluiten
          </button>
          <button type="submit">Verstuur</button>
        </FlexElement>
      </FlexElement>
    </form>
  );
};

export { RsvpForm };
