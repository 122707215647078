import { FC, CSSProperties } from "react";
import styles from "./styles.module.css";

type Props = {
  children?: React.ReactNode;
  style?: CSSProperties;
};

const FlexElement: FC<Props> = (props) => (
  <div className={styles.element} style={props.style}>
    {props.children}
  </div>
);

export { FlexElement };
