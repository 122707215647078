import { FC, useEffect, useState } from "react";
import styles from "./styles.module.css";

type Props = {
  children?: React.ReactNode;
  width?: number | string;
};

const ModalElement: FC<Props> = (props) => {
  const [hasMounted, setHasMounted] = useState(false);
  const [hasMountedChildren, setHasMountedChildren] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  useEffect(() => {
    setHasMountedChildren(false);
    setTimeout(() => setHasMountedChildren(true), 50);
  }, [props.children]);

  return (
    <div className={styles.backdrop} style={{ opacity: hasMounted ? 1 : 0 }}>
      <div className={styles.content}>
        <div
          style={{
            width: props.width,
            ...(hasMountedChildren && {
              transition: ".3s all",
              opacity: 1,
              transform: "translateY(0)",
            }),
          }}
          className={styles.modalCard}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};

export { ModalElement };
