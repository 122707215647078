import { FC, useCallback } from "react";
import { FlexElement } from "./elements/flex-element/flex-element";
import logoPng from "../assets/50jaar.png";
import { useModal } from "../domain/common/modal/use-modal.hook";
import { ModalElement } from "./elements/modal-element/modal-element";
import { RsvpForm } from "./templates/rsvp-form.template";
import { IInvitationResponse } from "../domain/invitation-responses/invitation-response.interface";
import { AcceptSuccessful } from "./templates/accept-successful.template";
import { DeclineSuccessful } from "./templates/decline-successful.template";

const App: FC = () => {
  const { openModal, modal } = useModal();
  const openRsvpModal = useCallback(async () => {
    const saveRsvpResponse = async (
      formdata: FormData
    ): Promise<IInvitationResponse> => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/invitation-responses`,
        {
          method: "POST",
          body: formdata,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to save rsvp response");
      }

      return response.json();
    };

    try {
      const rsvp = await openModal<IInvitationResponse | null>((resolve) => (
        <ModalElement width={600}>
          <RsvpForm
            onSubmit={async (formdata) =>
              resolve(await saveRsvpResponse(formdata))
            }
            onCancel={() => resolve(null)}
          />
        </ModalElement>
      ));

      if (rsvp?.willBeAttending === true) {
        openModal<IInvitationResponse | null>((resolve) => (
          <ModalElement width={600}>
            <AcceptSuccessful onClose={() => resolve(null)} />
          </ModalElement>
        ));
      } else if (rsvp?.willBeAttending === false) {
        openModal<IInvitationResponse | null>((resolve) => (
          <ModalElement width={600}>
            <DeclineSuccessful onClose={() => resolve(null)} />
          </ModalElement>
        ));
      }
    } catch (error) {
      openModal<IInvitationResponse | null>((resolve) => (
        <ModalElement width={600}>
          Er is iets misgegaan, probeer het later opnieuw. Neem persoonlijk
          contact op als de fout zich blijft voordoen.
        </ModalElement>
      ));
    }
  }, [openModal]);

  return (
    <>
      <FlexElement
        style={{
          maxWidth: 1200,
          margin: "0 auto",
        }}
      >
        <FlexElement
          style={{ minHeight: "100vh", justifyContent: "center", padding: 50 }}
        >
          <FlexElement style={{ justifyContent: "center" }}>
            <FlexElement style={{ alignItems: "center", flex: 0 }}>
              <img
                style={{ width: "75%", maxWidth: 350 }}
                src={logoPng}
                alt="Henk & Antine"
              />
              <p style={{ textAlign: "center" }}>
                11 MEI 2024 | 20.00 | BUITENWEG 80 ECK EN WIEL
              </p>
              <button onClick={openRsvpModal}>Klik hier voor RSVP</button>
            </FlexElement>
          </FlexElement>
        </FlexElement>
      </FlexElement>
      {modal}
    </>
  );
};

export { App };
