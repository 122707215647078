import { FC } from "react";
import { FlexElement } from "../elements/flex-element/flex-element";

type Props = {
  onClose: () => void;
};

const AcceptSuccessful: FC<Props> = (props) => (
  <FlexElement style={{ flexDirection: "column", gap: 30 }}>
    <h1>Bedankt voor het invullen!</h1>
    <p>We kijken er naar uit om je te zien op ons jubileum!</p>
    <p>Tot dan, Henk & Antine</p>
    <FlexElement style={{ alignItems: "flex-end" }}>
      <button type="button" onClick={props.onClose}>
        Sluiten
      </button>
    </FlexElement>
  </FlexElement>
);

export { AcceptSuccessful };
